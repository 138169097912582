<template>
  <div class="top-nav">
    <nav class="navbar navbar-expand-md" :class="{'blue-bar' : device === 'desktop'}">
      <div class="container">
        <!-- <router-link class="navbar-brand order-1 mobile_logo" :to="logoURL" v-if="device == 'mobile'">
          <img class="img-fluid" :src="srcLogo" alt="logo" height="45"/>
        </router-link> -->
        <div class="content-right d-flex order-2 order-md-1">
          <ul class="list-inline">
            <li class="list-inline-item">
            <!-- <li class="list-inline-item" v-if="marketerId===''"> -->
              <div id="st-trigger-effects" class="" v-if="marketerId===''">
                <button class="btn btn-outline-secondary text-light" name="nav-menu" data-effect="st-effect-9" v-b-toggle.nav-menu>
                  <i class="fa fa-bars"></i>
                </button>
              </div>
            </li>
            <li class="list-inline-item" v-if="showPricePlan && this.device === 'mobile'">
              <router-link :to="pricePlanLink">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ $t("top-nav.price-plan") }}
                </button>
              </router-link>
            </li>
            <li class="list-inline-item logo" v-if="device == 'mobile' && !isByteAgency">
              <router-link :class="['order-1', 'mobile_logo-maccabi']" v-if="isMaccabiAgency" :to="logoURL">
                <img :class="['img-fluid', 'logo-img-maccabi']" :src="srcLogo" alt="logo" height="45"/>
              </router-link>
              <router-link :class="['navbar-brand', 'order-1', 'mobile_logo', marketerId === ''? 'filtered_logo': '']"  v-else :to="logoURL">
                <img :class="['img-fluid', 'logo-img', marketerLogo && 'marketer_logo', isTargetAgency && 'big_logo']" :src="srcLogo" alt="logo" height="45"/>
              </router-link>
            </li>
            <li class="list-inline-item" v-if="device == 'mobile' && marketerId===''">
              <a :href="domesticLink">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ $t("top-nav.domestic-tourism") }}
                </button>
              </a>
            </li>
            <li class="list-inline-item" v-if="device === 'desktop' && currentPage !== 'home'">
              <router-link :to="logoURL">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ $t("menu.home") }}
                </button>
              </router-link>
            </li>
            <li class="list-inline-item" v-if="device == 'desktop' && showFellowLink">
              <a :href="fellowLink">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ $t("top-nav.domestic-tourism") }}
                </button>
              </a>
            </li>
            <li class="list-inline-item" v-if="showPricePlan && device === 'desktop'">
              <router-link :to="pricePlanLink">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ $t("top-nav.price-plan") }}
                </button>
              </router-link>
            </li>
            <!-- <li class="list-inline-item employer-message" v-if="enableSearchAgentState && device==='mobile'">
              {{ $t("top-nav.message-for-only-port-employee") }}
            </li> -->
            <li class="list-inline-item employer-message" v-if="isTargetAgency && device==='mobile'">
              ועד תש׳א מאורגן למונטנגרו
            </li>
            <!--<li class="list-inline-item">
              <button class="btn btnLogin" > {{$t('top-nav.login')}} <i class="far fa-user"></i> </button>
            </li>-->
            <li class="list-inline-item" v-if="false">
              <button class="btn btn-outline-secondary btn-theme-onWhite">
                {{ $t("top-nav.enrollment") }}
              </button>
            </li>
            <li class="list-inline-item btn-topVacation" v-if="false">
              <button class="btn btn-outline-secondary btn-theme-onTheme">
                {{ $t("top-nav.vacation-country") }}
              </button>
            </li>
          </ul>
        </div>
        <!-- <div class="employer-message order-1" v-if="enableSearchAgentState && device==='desktop'">
          {{ $t("top-nav.message-for-only-port-employee") }}
        </div> -->
        <div class="employer-message order-1" v-if="isTargetAgency && device==='desktop'">
          ועד תש׳א מאורגן למונטנגרו
        </div>
        <div :class="['navbar-brand', 'order-1','marketer_maccabi_Anchor']" :href="logoURL" v-if="device === 'desktop' && isMaccabiAgency">
          <img :class="['img-fluid', 'logo-img', 'marketer_logo']" :src="srcLogo" alt="logo" height="45" />
        </div>
        <a :class="['navbar-brand', 'order-1', (marketerId !== '' && !isDefaultLogo)? 'marketer_Anchor' : '']" :href="logoURL" v-else-if="device === 'desktop'" >
          <img :class="['img-fluid', 'logo-img', marketerLogo && 'marketer_logo', isTargetAgency && 'big_logo']" :src="srcLogo" alt="logo" height="45"/>
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue';
import TemplateTopNav from './TemplateTopNav';

export default {
  name: 'TopNavTheme0',
  mixins: [TemplateTopNav],
  components: {
    // NavMenu: () => import('@/components/menu/NavMenu'),
    // ContentWrapper: () => import('@/components/content/ContentWrapper')
    // MemberLogin: () => import('@/components/atoms/MemberLogin'),
    // MemberRegister: () => import('@/components/atoms/MemberRegister'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
};
</script>

<style scoped>
.top-nav .navbar.navbar-expand-md.blue-bar{
  background-color:rgba(37,116,181,0.8);
  height: 56px;
}
.top-nav ul li.employer-message{
  font-size: 1.1rem;
  font-weight: bold;
}
.top-nav div.employer-message{
  color: white;
  font-size: 1.5rem;
}
.logo-img {
  height: 45px;
  margin-left: 7rem;
  min-width: 100px;
  object-fit: fill;
}
.logo-img-maccabi {
  height: 120px;
}
.mobile_logo-maccabi {
  margin: auto;
}
.blue-bar .container .content-right ul {
  margin: 0;
}
.blue-bar .container .content-right ul li button.btn-theme-onWhite{
  padding: 0.375rem 0.75rem;
}
.list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
}
.list-inline {
  padding-right: 0;
}

.top-nav .menuarea .mobile_logo {
  margin-right: 10px;
}

.top-nav .marketer_Anchor .marketer_logo {
  height: 75px;
  margin-left: 7rem;
}
.top-nav .marketer_Anchor .marketer_logo.big_logo {
  height: 175px;
}
.top-nav .marketer_maccabi_Anchor .marketer_logo {
  height: 75px;
  margin-left: 5rem;
}

.top-nav .mobile_logo .marketer_logo {
    /* max-width: 200px;
    position: absolute;
    left: 10px;
    top: 10px; */
    height: 50px;
}
.top-nav .mobile_logo .marketer_logo.big_logo {
    height: 80px;
}

.top-nav nav.navbar .content-right {
    margin-left: 35px;
}
.list-inline-item.logo{
  vertical-align: middle;
}
@media (max-width: 990px) {
  .list-inline-item.logo{
    display: contents;
  }
  .btn-topVacation {
    display: none;
  }
  .he .btnDomestic {
    padding: 10px 5px;
  }
  .en .btnDomestic {
    padding: 5px;
  }
  .btnDomestic {
    max-width: 100px;
    line-height: 14px;
  }
  .top-nav nav.navbar .content-right {
      margin-right:0;
      margin-left:0;
      width: 100%;
  }
  ul.list-inline{
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
}
</style>
